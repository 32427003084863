import React from "react";
import { db } from "../firebase/firebase.js";
import { Formik, Field } from "formik";
import useFileDownloader from "../hooks/useFileDownloader.js";
import { StyleForm } from "../layout/index.js";
import Input from "../components/input/input.js";
import List from "../components/list/list.js";
import Checkbox from "../components/checkbox/Checkbox.js";
import Button from "../components/button/Button.js";
import Nav from "../components/navbar/Navbar.js";
import swal from "sweetalert";
import pdf from "../assets/hola.pdf";
import * as Yup from "yup";
import "../styles/register.css";
const file = {
  name: "Photo 3",
  thumb:
    "https://cors-anywhere.herokuapp.com/https://drive.google.com/file/d/1wlFQpZKgrO4gNVy2dcpJW_eWFYHvu3Wc/view?usp=sharing",
  file:
    "https://cors-anywhere.herokuapp.com/https://drive.google.com/file/d/1wlFQpZKgrO4gNVy2dcpJW_eWFYHvu3Wc" +
    Math.random(),
  filename: "photo-3.pdf",
};
function downloadFile1(absoluteUrl) {
  const link = document.createElement("a");
  link.href = absoluteUrl;
  link.download = "true";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  delete { link };
}

const RegisterScheme = Yup.object().shape({
  email: Yup.string()
    .email("Correo electrónico inválido")
    .required("El correo electrónico es requerido."),
  name: Yup.string().min(3, "Muy corto.").required("El nombre es requerido."),
  company: Yup.string().min(3, "Muy corto.").notRequired(),
  job: Yup.string().notRequired(),
  phone: Yup.number().min(9, "El teléfono es muy corto.").notRequired(),
  contacto: Yup.string().notRequired(),
  //referido: Yup.string().required("Selecciona una opción"),
  terms: Yup.string().oneOf(
    ["true"],
    "Debes aceptar los términos y condiciones."
  ),
});
const mostrarAlert = () =>
  swal({
    title: "¡Gracias por formar parte de nuestro webinar!",
    text: "En breve un experto se contactara contigo.",
    icon: "success",
  });
const Register = () => {
  const [downloadFile, downloaderComponentUI] = useFileDownloader();

  //const download = pdf;
  return (
    <header className="home">
      <Nav></Nav>
      <div className="banner"></div>
      <div className="form">
        <Formik
          initialValues={{
            name: "",
            company: "",
            email: "",
            date: new Date(),
            phone: "",
            job: "",
            terms: false,
            //referido: "Ergosolar",
          }}
          validationSchema={RegisterScheme}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            await db.collection("leads").add(values);
            //mostrarAlert();
            //downloadFile(file);
            downloadFile1(
              "https://drive.google.com/file/d/1C93MqkZyqxfg4UBtwZLEDM61XfNwXoUH"
            );
            setSubmitting(false);
            resetForm();
          }}
        >
          {({ isSubmitting, isValid }) => (
            <StyleForm>
              <center>
                <h1 className="headerForm">Guía Práctica</h1>
              </center>
              <center className="body">
                ¿CÓMO ENTENDER LA INICIATIVA DE REFORMA A LA LEY DE LA INDUSTRIA
                ELÉCTRICA (LIE)
              </center>
              <div className="body">
                <br></br>Descarga gratis esta guía práctica e infórmate de lo
                que ha pasado en el último año sobre temas energéticos.
              </div>
              <Field
                type="text"
                name="name"
                label="Nombre completo *"
                component={Input}
              />
              <Field
                type="email"
                name="email"
                label="Correo electrónico*"
                component={Input}
              />
              <Field
                type="text"
                name="company"
                label="Empresa*"
                component={Input}
              />
              <Field type="text" name="job" label="Puesto" component={Input} />
              <Field
                type="text"
                name="phone"
                label="Teléfono"
                component={Input}
              />
              {/*<select name="referido">
                <option hidden>Seleccione una opción</option>
                <option value={"Sánchez Devanny"}>Sánchez Devanny</option>
                <option value={"Ergosolar"}>ErgoSolar</option>
                <option value={"Otro"}>Otro</option>
              </select>*/}
              <div className="check">
                <Field
                  type="checkbox"
                  name="terms"
                  label=""
                  component={Checkbox}
                />
                <center>
                  <div className="terms">
                    <a href="http://www.ergosolar.mx/aviso-privacidad/">
                      Acepto el aviso de privacidad.
                    </a>
                  </div>
                </center>
              </div>
              <Button type="submit" disabled={!isValid || isSubmitting}>
                Descargar
              </Button>
              <div>{downloaderComponentUI}</div>
            </StyleForm>
          )}
        </Formik>
      </div>
    </header>
  );
};

export default Register;
